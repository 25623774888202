import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  function clickMenu(page) {
    document.body.classList.remove("menu--open");
    window.location.replace(`/#${page}`);
  }

  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="footer__wrapper">
            <div className="list__wrapper">
              <ul className="footer__list">
                <li
                  className="footer__list--item"
                  onClick={() => clickMenu("home")}
                >
                  <Link className="footer__item--anchor" to="/">
                    Home
                  </Link>
                </li>
                <li
                  className="footer__list--item"
                  onClick={() => clickMenu("diensten")}
                >
                  <Link className="footer__item--anchor">Diensten</Link>
                </li>
                <li
                  className="footer__list--item"
                  onClick={() => clickMenu("tarieven")}
                >
                  <Link className="footer__item--anchor">Tarieven</Link>
                </li>
                <li
                  className="footer__list--item"
                  onClick={() => clickMenu("contact")}
                >
                  <Link className="footer__item--anchor">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="list__wrapper">
              <h3 className="footer__smalltitle">Volg ons:</h3>
              <ul className="socials__list">
                <li className="socials__list--item">
                  <a
                    className="socials__list--anchor"
                    target="_blank"
                    href="https://www.instagram.com/trimsalon.aayden/"
                  >
                    <FontAwesomeIcon icon="fa-brands fa-instagram" />
                  </a>
                </li>
                <li className="footer__list--item">
                  <a
                    className="socials__list--anchor"
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100088411924744"
                  >
                    <FontAwesomeIcon icon="fa-brands fa-facebook" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="credits">
            <small className="credits__small">
              Made by{" "}
              <a href="https://sennaoudshoorn.nl" target="_blank">
                Senna Oudshoorn
              </a>
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
