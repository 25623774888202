import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../assets/aayden.svg";
import { Link } from "react-router-dom";

const Nav = () => {
  const ctaButton = useRef(null);

  function openMenu() {
    document.body.classList += " menu--open";
  }

  function closeMenu() {
    document.body.classList.remove("menu--open");
  }

  function clickMenu(page) {
    document.body.classList.remove("menu--open");
    if (page) {
      window.location.replace(`/#${page}`);
    }
  }

  return (
    <nav id="home">
      <div className="nav__container">
        <Link to="/">
          <img src={Logo} className="logo" alt="" />
        </Link>
        <ul className="nav__links">
          <li className="nav__list" onClick={() => clickMenu("over_ons")}>
            <Link className="nav__link link__hover-effect">Over ons</Link>
          </li>
          <li className="nav__list" onClick={() => clickMenu("diensten")}>
            <Link className="nav__link link__hover-effect">Diensten</Link>
          </li>
          <li className="nav__list" onClick={() => clickMenu("contact")}>
            <Link className="nav__link link__hover-effect">Contact</Link>
          </li>
          <li className="nav__list">
            <a
              className="cta-button"
              href="https://aayden.salonized.com/widget_bookings/new"
              target="_blank"
            >
              Maak afspraak
            </a>
          </li>
          <button className="btn__menu" onClick={openMenu}>
            <FontAwesomeIcon icon="fa-solid fa-bars-staggered" />
          </button>
        </ul>
      </div>
      <div className="menu__backdrop">
        <div className="menu__bar">
          <div className="menu__logo">
            <img className="menu__logo--img" src={Logo} alt="" />
          </div>
          <div className="menu__btn">
            <button className="btn__menu btn__menu--close" onClick={closeMenu}>
              <FontAwesomeIcon icon="times" />
            </button>
          </div>
        </div>
        <ul className="menu__links">
          <li className="menu__list" onClick={() => clickMenu("over_ons")}>
            <Link className="menu__link">Over ons</Link>
          </li>
          <li className="menu__list" onClick={() => clickMenu("diensten")}>
            <Link className="menu__link">Diensten</Link>
          </li>
          <li className="menu__list" onClick={() => clickMenu("contact")}>
            <Link className="menu__link">Contact</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Nav;
