import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Services = () => {
  return (
    <section className="services" id="diensten">
      <div className="container">
        <div className="row">
          {/* <div className="section__description">
            <h3 className="section__description--title">Diensten</h3>
            <p className="section__description--para">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
            </p>
          </div> */}
          <ul className="services__list">
            <li className="services__list--item">
              <FontAwesomeIcon icon="fa-solid fa-scissors" className="services__list--icon" />
              <h3 className="services__title">Trimmen</h3>
              <p className="services__para">
                Professioneel stylen voor je huisdier.
              </p>
            </li>
            <li className="services__list--item">
              <FontAwesomeIcon icon="fa-solid fa-bath" className="services__list--icon" />
              <h3 className="services__title">Wassen</h3>
              <p className="services__para">
                Verwenning en hygiëne in één.
              </p>
            </li>
            <li className="services__list--item">
              <FontAwesomeIcon icon="fa-solid fa-hand-sparkles" className="services__list--icon" />
              <h3 className="services__title">Overig</h3>
              <p className="services__para">
                Extra zorg voor een blije viervoeter.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Services;
