import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pricesImage from "../assets/img/foto2.jpg";

const Prices = () => {
  return (
    <section className="prices" id="tarieven">
      <div className="container">
        <div className="row">
          {/* <div className="section__description">
                        <h3 className="section__description--title">Prijzen</h3>
                        <p className="section__description--para">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        </p>
                    </div> */}
          <div className="prices__wrapper">
            <div className="prices__image">
              <img src={pricesImage} alt="" className="prices__image--img" />
            </div>
            <div className="prices__description">
              <h2 className="prices__description--title">Afspraak maken.</h2>
              <p className="prices__description--para">
                Bij Aayden maken we het gemakkelijk om een afspraak te plannen
                voor de verzorging van uw geliefde huisdier. U kunt eenvoudig
                een afspraak maken door op de knop rechtsonder in uw beeld te
                klikken, of door op{" "}
                <a
                  href="https://aayden.salonized.com/widget_bookings/new"
                  target="_blank"
                >
                  deze link
                </a>{" "}
                te klikken. Als dit niet lukt, kunt u ook altijd telefonisch
                contact opnemen met <a href="tel:0640111465">06 40 11 14 65</a>.
                We streven ernaar om elke afspraak snel en gemakkelijk te
                regelen, zodat uw huisdier de zorg krijgt die het verdient.
                Bedankt voor het vertrouwen in Aayden voor de verzorging van uw
                trouwe metgezel. We kijken ernaar uit om u te verwelkomen!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Prices;
