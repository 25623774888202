import React from "react";
import Contact from "../components/Contact";
import Landing from "../components/Landing";
import Prices from "../components/Prices";
import Services from "../components/Services";

const Home = () => {
  return (
    <>
      <Landing />
      <Services />
      <Prices />
      <Contact />
    </>
  );
};

export default Home;
