import React from "react";
import ErrorImage from "../assets/404.svg";

const NotFound = () => {
  return (
    <section className="notfound">
        <div className="container">
            <div className="row">
                <div className="notfound__wrapper">
                    <img className="notfound__img" src={ErrorImage} />
                </div>
            </div>
        </div>
    </section>
  );
};

export default NotFound;
