import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contact = () => {
  //   const form = useRef();
  //   const loading = useRef();
  //   const success = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   loading.current.style.display = "block";
  //   form.current.style.display = "none";

  //   emailjs
  //     .sendForm(
  //       "service_rl5ih3f",
  //       "template_n1dqsbc",
  //       form.current,
  //       "k-Py2NOiNbQ06lq8a"
  //     )
  //     .then(
  //       (result) => {
  //         loading.current.style.display = "none";
  //         success.current.style.display = "block";
  //         form.current.style.display = "none";
  //       },
  //       (error) => {
  //           loading.current.style.display = 'block';
  //           form.current.style.display = 'none';
  //           alert('Something went wrong, please try again later. Or contact the developer directly on: sennaoudshoorn@gmail.com');
  //       }
  //     );
  // };

  return (
    <section className="contact" id="contact">
      <div className="container">
        <div className="row">
          <div className="contact__head">
            <h2 className="contact__head--title">Maak een afspraak!</h2>
            <p className="contact__head--para">
              U kunt ons contacteren op onderstaande gegevens.
            </p>
          </div>
          <div className="contact__wrapper">
            <div className="info">
              <a href="tel:0640111465">
                <span>TEL</span>06 40 11 14 65
              </a>

              <a href="mailto:info@aayden.nl">
                <span>EMAIL</span>INFO@AAYDEN.NL
              </a>
              <div className="cta-button-wrapper">
                <a
                  className="cta-button"
                  href="https://aayden.salonized.com/widget_bookings/new"
                  target="_blank"
                >
                  Maak afspraak
                </a>
              </div>
            </div>

            {/* <div ref={loading} className="contact__overlay contact__overlay--loading">
              <FontAwesomeIcon icon="fa-solid fa-spinner" />
            </div>
            <div ref={success} className="contact__overlay contact__overlay--success">
              Bedankt, u ontvangt zo snel mogelijk een reactie!
            </div> */}
            {/* <form className="contact__form" ref={form} onSubmit={sendEmail}>
              <input
                type="text"
                name="user_name"
                className="contact__form--input"
                placeholder="Naam"
                required
              />
              <input
                type="email"
                name="user_email"
                className="contact__form--input"
                placeholder="E-mailadres"
                required
              />
              <input
                type="text"
                name="user_petname"
                className="contact__form--input"
                placeholder="Naam van uw dier"
                required
              />
              <textarea
                className="contact__form--input contact__form--textarea"
                name="message"
                placeholder="Bericht"
                required
              />
              <input type="submit" value="Verzenden" className="btn" />
            </form> */}
            <div className="contact__map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2454.352834377547!2d4.357188315985993!3d52.03688647967117!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b66e06e61189%3A0x5c0e536d314f0883!2sAnthony%20Fokkersingel%2048%2C%202497%20BG%20Den%20Haag!5e0!3m2!1snl!2snl!4v1669800175115!5m2!1snl!2snl"
                width="100%"
                height="450"
                style={{ border: 0, borderRadius: 8 }}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
