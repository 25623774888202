import React from "react";
import Banner from "../assets/img/foto1.jpg";
import LandingBanner from "../assets/img/foto3.jpg";
import Paw from "../assets/paw.svg";
import Charlie from "../assets/img/charlie.jpg";
import DescriptionImage from "../assets/descriptionImage.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Landing = () => {
  return (
    <div className="landing container mt-5">
      <div className="landing__banner">
        <div className="landing__banner--description">
          <div className="row">
            <div className="banner__wrapper">
              <h1 className="banner__description--title">
                Trimsalon <em>Aayden</em>
              </h1>
              <p className="banner__description--para">
                Bij Aayden draait alles om de zorg en liefde die we hebben voor
                uw huisdier. We zijn toegewijd aan het bieden van de allerbeste
                verzorging. Ontdek onze passie en expertise voor uw geliefde
                metgezel.
              </p>
              <div className="cta-button-wrapper">
                <a
                  className="cta-button"
                  href="https://aayden.salonized.com/widget_bookings/new"
                  target="_blank"
                >
                  Maak afspraak
                </a>
              </div>
            </div>
          </div>
        </div>
        <img src={LandingBanner} alt="" className="landing__banner--img" />
      </div>
      <div className="container">
        <div className="row">
          <div className="landing__description" id="over_ons">
            <div className="landing__footer">
              <img src={Paw} alt="" className="landing__footer--paw" />
            </div>
            <div className="landing__description--wrapper">
              <h2 className="landing__description--title">Over ons</h2>
              <p className="landing__description--parastrong">
                Welkom bij Aayden, een gezellige trimsalon bij een toegewijde
                dierenliefhebster thuis in Ypenburg. Hier draait alles om uw
                huisdier, waar liefde en professionele zorg samenkomen. Met
                passie en expertise maken we uw huisdier weer stralend en
                gezond. We kijken ernaar uit om u en uw geliefde metgezel te
                verwelkomen!
              </p>
              <p className="landing__description--para">
                Als dierenliefhebster en trotse eigenaar van Aayden ben ik
                toegewijd aan het welzijn van uw huisdier. Met mijn ervaring
                zorg ik ervoor dat uw harige vriend zich thuis voelt terwijl ik
                hen verzorg. Samen streven we naar een gezond, gelukkig en
                goedverzorgd huisdier. Bedankt voor het vertrouwen in Aayden.
              </p>
              <a href="/#contact" className="btn">
                Maak een afspraak{" "}
                <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
              </a>
            </div>
            <div className="landing__description--wrapper">
              <img src={Banner} alt="" className="landing__description--img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
