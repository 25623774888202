import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="robots" content="index, follow" />
        <meta
          name="description"
          content="Trimsalon Aayden: Liefdevolle vachtverzorging in Ypenburg, Den Haag. Ontdek stijlvolle make-overs voor jouw huisdier."
        />
        <title>Trimsalon Aayden</title>
      </Helmet>
      <Router>
        <div className="App">
          <Nav />
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;
